import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { Flex, Box, Link } from '@pancakeswap/uikit'
import {
  StyledIconMobileContainer,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledToolsContainer,
} from 'components/uikit/components/Footer/styles'
import LogoWidthText from 'components/LogoWidthText'
import SocialLinks from 'components/uikit/components/Footer/Components/SocialLinks'
import { MOBILE_MENU_HEIGHT } from 'components/uikit/widgets/Menu/config'
import { WALLET_USER_AGENT_FLAG } from 'config'

export default function Component({ ...props }) {
  const { t } = useTranslation()

  const items = [
    { label: t('Footer.コミュニティ'), href: '/communities' },
    { label: t('Footer.トークン'), href: '/tokenomics' },
    { label: t('Footer.監査'), href: 'https://github.com/niziswap' },
    { label: t('Footer.サポート'), href: '/support' },
    { label: t('Footer.ガイド'), href: '/guide' },
    { label: t('Footer.documentation'), href: 'https://docs.niziswap.finance/' },
  ]

  const tos = [
    { label: t('Footer.terms'), href: '/terms-of-use' },
    { label: t('Footer.privacy'), href: '/privacy-policy' },
  ]

  return (
    <StyledFooter {...props}>
      <StyledContent
        p={['40px 16px', null, '40px']}
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        alignItems="center"
        width={['100%']}
      >
        <LogoWidthText />
        {/* nav START */}
        <Flex
          flexDirection={['column', null, 'row']}
          justifyContent="space-between"
          alignItems="flex-start"
          m={['42px 0', null, '0px']}
        >
          {items.map((item) => {
            if (item.href.includes('http')) {
              return (
                <StyledLink key={item.label} href={item.href} rel="noreferrer noopener">
                  {item.label}
                </StyledLink>
              )
            }
            return (
              <StyledLink key={item.label} as={NavLink} to={item.href} rel="noreferrer noopener">
                {item.label}
              </StyledLink>
            )
          })}
        </Flex>
        {/* nav END */}
        {/* media START */}
        <SocialLinks />

        {/* media END */}
      </StyledContent>
      <Flex
        width={['100%']}
        justifyContent="center"
        p="1rem"
        alignItems="center"
        mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0']}
        flexDirection={['column-reverse', null, 'row']}
      >
        <StyledCopy>&copy; 2021 NIZIswap</StyledCopy>
        <Box>
          {tos.map((item) => {
            return (
              <StyledLink
                key={item.label}
                as={NavLink}
                to={item.href}
                rel="noreferrer noopener"
                mb={['1rem', null, '0px']}
              >
                {item.label}
              </StyledLink>
            )
          })}
        </Box>
      </Flex>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  html[data-useragent*=${WALLET_USER_AGENT_FLAG}] & {
    display: none;
  }
`
const StyledContent = styled(Flex)`
  border-color: #f0efef;
  border-width: 1px 0 1px 0;
  border-style: solid;
`
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.contrast};
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: normal;
  &:hover {
    text-decoration: none;
  }

  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 0rem;
  }
`
const StyledCopy = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.contrast};
  font-size: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
`
