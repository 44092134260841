import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 1,
    stakingToken: serializedTokens.cmx,
    earningToken: serializedTokens.nizi,
    contractAddress: {
      97: '',
      56: '0x8EFa96261d06679FF0B54E8775C6e52753493a85',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1',
    sortOrder: 999,
    isFinished: false,
  },
  // {
  //   sousId: 2,
  //   stakingToken: serializedTokens.hln,
  //   earningToken: serializedTokens.coss,
  //   contractAddress: {
  //     97: '',
  //     56: '0x1e33af12194e4991bC7441cD5C58742c3d0bc66D',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '15',
  //   sortOrder: 998,
  //   isFinished: false,
  // },
  // {
  //   sousId: 3,
  //   stakingToken: serializedTokens.hln,
  //   earningToken: serializedTokens.coss,
  //   contractAddress: {
  //     97: '',
  //     56: '0x5b2b9a59F1278EcDfC01C9eDFb6c42B95704634B',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: false,
  //   tokenPerBlock: '15',
  //   sortOrder: 999,
  //   isFinished: false,
  // },
  {
    sousId: 4,
    stakingToken: serializedTokens.hln,
    earningToken: serializedTokens.coss,
    contractAddress: {
      97: '',
      56: '0x4BD6C5E60188f1c600549ABfBa5BB621B60b72E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '15',
    sortOrder: 999,
    isFinished: false,
  },
  // {
  //   sousId: 5,
  //   stakingToken: serializedTokens.cmx,
  //   earningToken: serializedTokens.nizi,
  //   contractAddress: {
  //     97: '',
  //     56: '0x8EFa96261d06679FF0B54E8775C6e52753493a85',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.1',
  //   sortOrder: 999,
  //   isFinished: false,
  // },
  // {
  //   sousId: 6,
  //   stakingToken: serializedTokens.hln,
  //   earningToken: serializedTokens.coss,
  //   contractAddress: {
  //     97: '',
  //     56: '0x4BD6C5E60188f1c600549ABfBa5BB621B60b72E4',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: false,
  //   tokenPerBlock: '15',
  //   sortOrder: 999,
  //   isFinished: false,
  // },
]

export default pools
