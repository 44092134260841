import React from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItemUikit from '../MenuItem/MenuItem'
import IconComponent from '../Svg/IconComponent'
import { MenuItemsProps } from './types'

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <Flex {...props}>
      {items.map(({ label, items: menuItems = [], href, icon = '' }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const menuItemsWithStyle = menuItems.map((el) => {
          return { ...el, style: { color: '#353535' } }
        })
        const isActive = activeItem === href
        return (
          <DropdownMenu
            key={label}
            items={menuItemsWithStyle}
            py={1}
            px={16}
            activeItem={activeSubItem}
            style={{ color: 'red !important' }}
          >
            <MenuItem variant="subMenu" href={href} isActive={isActive} statusColor={statusColor}>
              {label || <IconComponent iconName={icon} color={isActive ? 'secondary' : 'textSubtle'} />}
            </MenuItem>
          </DropdownMenu>
        )
      })}
    </Flex>
  )
}

export const MenuItem = styled(MenuItemUikit)`
  color: #353535;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  font-weight: bold;
`

export default MenuItems
