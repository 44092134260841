import React from 'react'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import LogoWithText from 'components/LogoWidthText'
import logo from 'assets/logos/logo.svg'
import Flex from '../../../components/Box/Flex'

interface Props {
  isDark: boolean
  href: string
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    width: 160px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .right-eye {
    animation-delay: 20ms;
  }
  &:hover {
    .left-eye,
    .right-eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const StyledLogo = styled.img`
  display: block;
  width: 1.5rem;
`

const Logo: React.FC<Props> = ({ isDark, href }) => {
  const isAbsoluteUrl = href.startsWith('http')
  const innerLogo = (
    <>
      <StyledLogo className="mobile-icon" src={logo} alt="logo" />

      <div className="desktop-icon" style={{ height: '100%' }}>
        <LogoWithText />
      </div>
      {/* <LogoWithTextIcon className="desktop-icon" isDark={isDark} /> */}
    </>
  )

  return (
    <Flex>
      <StyledLink to={href} aria-label="Pancake home page">
        {innerLogo}
      </StyledLink>
    </Flex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
