import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-Roman.otf);
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-Bd.otf);
    font-weight: bold;
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-Blk.otf);
    font-weight: 900;
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-Hv.otf);
    font-weight: 800;
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-Md.otf);
    font-weight: 500;
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-It.otf);
    font-style: italic;
  }
  @font-face {
    font-family: HelveticaNeueLTPro;
    src: url(/fonts/helvetica/HelveticaNeueLTPro-BdIt.otf);
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: Baloo;
    src: url(/fonts/baloo/Baloo-Regular.ttf);
  }
  * {
    font-family: HelveticaNeueLTPro, 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    line-height: 1.5;
    letter-spacing: 1px;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
