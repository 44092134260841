import React from 'react'
import styled from 'styled-components'
import { darkColors } from '../../../theme'
import { FlexProps } from '../../Box'
import Flex from '../../Box/Flex'
import Dropdown from '../../Dropdown/Dropdown'
import Link from '../../Link/Link'
import IconComponent from '../../Svg/IconComponent'
import { socials } from '../config'

const SocialLinks: React.FC<FlexProps> = ({ ...props }) => (
  <Flex {...props}>
    {socials.map((social, index) => {
      function Iconrender() {
        if (social.icon) {
          const iconProps = {
            iconName: social.icon,
            width: '1.5rem',
            color: darkColors.textSubtle,
            style: { cursor: 'pointer' },
          }
          return <IconComponent {...iconProps} />
        }
        if (social.img) {
          return <StyledImg src={social.img} alt={social.label} bg={social.bg} />
        }
      }
      const mr = index < socials.length - 1 ? '24px' : 0
      return (
        <Link external key={social.label} href={social.href} aria-label={social.label} mr={mr}>
          {Iconrender()}
        </Link>
      )
    })}
  </Flex>
)

const StyledImg = styled.img<{ bg?: string }>`
  background: ${({ bg }) => bg ?? '#3b5998'};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 8px;
  object-fit: contain;
`

export default React.memo(SocialLinks, () => true)
