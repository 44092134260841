import styled from 'styled-components'
import {WALLET_USER_AGENT_FLAG} from 'config'
import { Flex } from '../Box'

const StyledBottomNav = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 5px 8px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  html[data-useragent*=${WALLET_USER_AGENT_FLAG}] & {
    display: none;
  }
`

export default StyledBottomNav
