import React, { useState } from 'react'
import styled from 'styled-components'
import logoText from 'assets/logos/logobanner.png'
import logo from 'assets/logos/logo.svg'
import logoGif from 'assets/logos/logo.gif'

export default function Component() {
  const [showGif, setShowGif] = useState(false)

  return (
    <StyledLogoWrapper
      onMouseEnter={() => {
        setShowGif(true)
      }}
      onMouseLeave={() => {
        setShowGif(false)
      }}
    >
      {!showGif && <StyledLogo className="normal" src={logo} alt="logo" />}
      {showGif && <StyledLogo className="gif" src={logoGif} alt="logo" />}
      <StyledLogoText>NIZIswap</StyledLogoText>
    </StyledLogoWrapper>
  )
}

const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:hover {
    .normal {
      display: none !important;
    }
    .gif {
      display: block !important;
    }
  }
`
const StyledLogo = styled.img`
  width: 1.5rem;
  display: block;

  &.gif {
    display: none;
  }
`
const StyledText = styled.img`
  width: 6rem;
  display: block;
  padding-top: 0.5rem;
  margin-left: 0.5rem;
`
const StyledLogoText = styled.div`
  display: block;
  padding-top: 8px;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  color: #515151;
  font-family: 'Baloo';
`
