import styled from 'styled-components'
import { Flex } from '../Box'

const StyledSubMenuItems = styled(Flex)`
  background-color: rgba(196, 196, 196, 0.4);
  /* box-shadow: inset 0px -2px 0px -8px rgba(133, 133, 133, 0.1); */
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  z-index: 10;
`

export default StyledSubMenuItems
