import React from 'react'
import styled from 'styled-components'
import logo from 'assets/logos/logo.svg'

export default function Component() {
  return <StyledSpinner src={logo} alt="spinner" />
}

const StyledSpinner = styled.img`
  width: 6rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotateY(180deg);
    }
    50% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(180deg);
    }
  }
`
