import discordImg from 'assets/logos/discord.png'
import mediumImg from 'assets/logos/medium.png'
import telegramImg from 'assets/logos/TGICON.svg'
import twitterImg from 'assets/logos/twitter.png'
import { Language } from '../LangSelector/types'
import { FooterLinkType, socialsType } from './types'

export const footerLinks: FooterLinkType[] = [
  {
    label: 'About',
    items: [
      {
        label: 'Contact',
        href: 'https://docs.pancakeswap.finance/contact-us',
      },
      {
        label: 'Blog',
        href: 'https://pancakeswap.medium.com/',
      },
      {
        label: 'Community',
        href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      },
      {
        label: 'CAKE',
        href: 'https://docs.pancakeswap.finance/tokenomics/cake',
      },
      {
        label: '—',
      },
      {
        label: 'Online Store',
        href: 'https://pancakeswap.creator-spring.com/',
        isHighlighted: true,
      },
    ],
  },
  {
    label: 'Help',
    items: [
      {
        label: 'Customer',
        href: 'Support https://docs.pancakeswap.finance/contact-us/customer-support',
      },
      {
        label: 'Troubleshooting',
        href: 'https://docs.pancakeswap.finance/help/troubleshooting',
      },
      {
        label: 'Guides',
        href: 'https://docs.pancakeswap.finance/get-started',
      },
    ],
  },
  {
    label: 'Developers',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/niziswap',
      },
      {
        label: 'Documentation',
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: 'Bug Bounty',
        href: 'https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty',
      },
      {
        label: 'Audits',
        href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      },
      {
        label: 'Careers',
        href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      },
    ],
  },
]

export const socials: socialsType[] = [
  {
    label: 'Discord',
    href: 'https://discord.gg/FvJQteB8DE',
    img: discordImg,
    bg: '#5865F1',
  },
  {
    label: 'Medium',
    href: 'https://medium.com/@niziswap',
    img: mediumImg,
    bg: '#464646',
  },
  {
    label: 'Twitter',
    href: 'https://twitter.com/NiziSwap',
    img: twitterImg,
    bg: '#1DA1F2',
  },
  {
    label: 'Telegram',
    href: 'https://t.me/joinchat/iCRSK5Ph8CA3MDZl',
    img: telegramImg,
    bg: '#28A8E9',
  },
]

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}))
