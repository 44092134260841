import React from 'react'
import { BASE_URL } from 'config'
import HachiWalletImg from 'assets/logos/wallet_logo.png'

const NiziIcon: React.FC<any> = (props) => {
  return (
    <img
      style={{ marginBottom: '10px' }}
      src={`${BASE_URL}/images/tokens/0x7fA466Ca58535B5B60195b1AA9F136996Bf6ff79.png`}
      alt="nizi"
      {...props}
    />
  )
}

export const HachiWalletIcon: React.FC<any> = (props) => {
  return <img style={{ marginBottom: '10px' }} src={HachiWalletImg} alt="wallet" {...props} />
}

export default NiziIcon
