import React from 'react'
import styled from 'styled-components'
import { Box } from '../Box'
import MenuItemUikit from '../MenuItem/MenuItem'
import StyledSubMenuItems from './styles'
import { SubMenuItemsProps } from './types'

const SubMenuItems: React.FC<SubMenuItemsProps> = ({ items = [], activeItem, ...props }) => {
  return (
    <StyledSubMenuItems justifyContent={['start', null, 'center']} {...props} pl={['12px', null, '0px']} pb={2}>
      {items.map(
        ({ label, href }) =>
          label && (
            <Box key={label} mr="32px">
              <MenuItem href={href} isActive={href === activeItem} variant="subMenu">
                {label}
              </MenuItem>
            </Box>
          ),
      )}
    </StyledSubMenuItems>
  )
}

export const MenuItem = styled(MenuItemUikit)`
  color: ${({ theme, isActive }) => theme.colors.contrast};

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.colors.contrast};
  }
`

export default SubMenuItems
