import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from 'components/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/farms/hooks'
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { WALLET_USER_AGENT_FLAG } from '../../config'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  // useEffect(() => {
  //   const ua = navigator.userAgent
  //   // ua包含特定字符串，判断为钱包浏览器
  //   // 手动设置语言
  //   if (ua.includes(WALLET_USER_AGENT_FLAG)) {
  //     const l = languageList.find((el) => {
  //       return el.code === 'ja'
  //     })
  //     setLanguage(l)
  //     // const langs = navigator.languages
  //     // langs.find((l: string) => {
  //     //   return languageList.find((el) => {
  //     //     if (el.locale.toLowerCase() === l || el.code.toLowerCase() === l) {
  //     //       setLanguage(el)
  //     //       return true
  //     //     }
  //     //     return false
  //     //   })
  //     //   return false
  //     // })
  //   }
  // }, [setLanguage])

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings color="contrast" />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy NIZI')}
      {...props}
    />
  )
}

export default Menu
