import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'NIZI',
    lpAddresses: {
      97: '0x7fA466Ca58535B5B60195b1AA9F136996Bf6ff79',
      56: '0x7fA466Ca58535B5B60195b1AA9F136996Bf6ff79',
    },
    token: serializedTokens.nizi,
    quoteToken: serializedTokens.nizi,
  },
  {
    pid: 1,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.bnb,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'CMX-USDT LP',
    lpAddresses: {
      97: '0x950FC258722110c2A86dDE3FF0a2a90ad13b5DE7',
      56: '0x950FC258722110c2A86dDE3FF0a2a90ad13b5DE7',
    },
    token: serializedTokens.cmx,
    quoteToken: serializedTokens.usdt,
  },
]

export default farms
