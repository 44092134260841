import { darkColors, lightColors } from '../../theme/colors'
import { RadioTheme } from './types'

export const light: RadioTheme = {
  handleBackground: lightColors.backgroundAlt,
}

export const dark: RadioTheme = {
  handleBackground: darkColors.backgroundAlt,
}
